<template>
    <section class="pa-4 formular">
        <v-container v-if="document_ready" fluid>

            <v-alert text type="info" class="caption" v-if="true">
                <div v-for="([key,value],i) in Object.entries(getContent)" :key="i">
                    <span>{{ i }} - {{ key }}: <strong>{{ g_isDefined(value) }}</strong></span><br>
                </div>
            </v-alert>

            <v-form @submit.prevent="submit" id="form" ref="form" class="formular">
                <v-row :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4 pt-1">

                    <v-col cols="12" xs="12" sm="7" md="6">
                        <v-select outlined dense color="gold" ref="cat_sel"
                                  :label="g_i18_keys('category')"
                                  item-text="alias"
                                  item-value="value"
                                  :items="select_category"
                                  v-model="content.category"
                                  hide-details
                                  return-object
                                  @change="section"
                        />
                    </v-col>
                    <v-col cols="6" xs="6" sm="3" md="3">
                        <v-select outlined dense color="gold" ref="type_sel"
                                  :label="g_i18_keys('type')"
                                  item-text="alias"
                                  item-value="value"
                                  :items="select_type"
                                  v-model="content.type"
                                  hide-details
                                  :disabled="type_disabled"
                        />
                    </v-col>
                    <v-col cols="6" xs="6" sm="2" md="3">
                        <v-text-field outlined dense color="gold"
                                      :label="g_i18_keys('sort')"
                                      v-model="content.sort"
                                      hide-details
                        />
                    </v-col>

                    <v-col cols="12">
                        <v-text-field outlined dense color="gold"
                                      :label="g_i18_keys('title')"
                                      v-model="content.title"
                                      hide-details
                        />
                    </v-col>
                    <v-col cols="12" v-if="content.type === 'Article'">
                        <v-textarea outlined dense color="gold"
                                    :label="g_i18_keys('text')"
                                    auto-grow
                                    v-model="content.text"
                        />
                    </v-col>

                    <v-col cols="12" v-if="false">
                        <!--<template>
                            <v-file-input
                                v-model="content.file"
                                color="gold"
                                :label="'g_i18_keys()'"
                                prepend-icon="mdi-paperclip"
                                outlined
                                dense
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip
                                        color="gold"
                                        dark
                                        label
                                        small
                                    >
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </template>-->
                        <!--<template>
                            <v-file-input
                                v-model="content.file"
                                color="gold" outlined dense
                                :label="g_i18_keys('file')"
                            />
                        </template>-->
                        <input accept="image/jpg,image/jpeg" type="file" ref="inputUpload" @change="test">
                    </v-col>

                    <v-col cols="12" v-if="false">
                        <span v-if="content.type === 'File'">
                            <input accept="application/pdf" type="file" ref="inputUpload" @change="test">
                        </span>
                        <span v-if="content.type === 'Image'">
                            <input accept="image/jpg,image/jpeg" type="file" ref="inputUpload" @change="test">
                        </span>
                        <span v-if="content.type === 'Video'">
                            <v-text-field outlined dense color="gold"
                                          label="Vlozte url linku na video z YouTube"
                                          v-model="content.file"
                                          hide-details
                            />
                            <iframe :id="'video'" type="text/html" width="640" height="360"
                                    :src="'https://www.youtube.com/embed/' + content.file"
                                    frameborder="0"/>
                        </span>
                    </v-col>

                </v-row>

                <v-row v-if="content.type !== 'Article'" :class="{ 'rgg-grey' : $vuetify.theme.dark }"
                       class="grey lighten-4 rounded mb-4 pt-1">
                    <v-col cols="12" v-show="content.type === 'File'" align="center">
                        <v-btn
                            class="gold mb-3"
                            dark small depressed dense
                            @click="$refs.inputDocument.click()"
                        >
                            <v-icon small left>mdi-file-import-outline</v-icon>
                            {{ $t('content_management.dialog.type_file') }}
                        </v-btn>
                        <input v-show="false" accept="application/pdf" type="file" ref="inputDocument"
                               @change="filePreview">

                        <p class="info white--text rounded pa-2">
                            <v-icon color="white" left>mdi-file</v-icon>
                            <span class="font-weight-medium">{{ file_name.name }}</span>
                        </p>
                    </v-col>

                    <v-col cols="12" v-show="content.type === 'Image'" align="center">
                        <v-btn
                            class="gold mb-3"
                            dark small depressed dense
                            @click="$refs.inputUpload.click()"
                        >
                            <v-icon small left>mdi-file-import-outline</v-icon>
                            {{ $t('content_management.dialog.type_image') }}
                        </v-btn>
                        <input v-show="false" accept="image/jpg,image/jpeg" type="file" ref="inputUpload"
                               @change="imagePreview">

                        <v-col cols="12" align="center" v-if="edit">
                            <img v-if="content.file !== ''" class="home_img" :src="getImage"/>
                            <img v-if="img_url" class="home_img" :src="img_url"/>
                        </v-col>
                        <v-col class="create-content" cols="12" align="center" v-if="!edit">
                            <img v-if="img_url" :src="img_url"/>
                        </v-col>
                    </v-col>

                    <v-col cols="12" v-if="content.type === 'Video'" align="center">
                        <v-text-field outlined dense color="gold" prepend-inner-icon="mdi-youtube"
                                      :label="$t('content_management.dialog.video_id')"
                                      v-model="content.videoId"
                                      hide-details @change="reString"
                        />
                        <v-row no-gutters class="ma-4">
                            <v-col cols="12">
                                <iframe :id="'video'" type="text/html" width="640" height="360"
                                        :src="'https://www.youtube.com/embed/' + content.videoId"
                                        frameborder="0"/>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>

            </v-form>

            <!--BUTTONS-->
            <v-row v-if="!edit" align="center" no-gutters>
                <v-col cols="12" align="center">
                    <v-btn dense depressed color="gold" dark class="mx-1" @click="create_content">
                        <v-icon left>mdi-plus-circle</v-icon>
                        {{ $t('common.create') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else align="center" no-gutters>
                <v-col cols="4"></v-col>
                <v-col cols="4" align="center">
                    <v-btn dense depressed color="gold" dark class="mx-1" @click="update_content">
                        <v-icon left>mdi-pencil-circle</v-icon>
                        {{ $t('common.save') }}
                    </v-btn>
                </v-col>
                <v-col cols="4" align="right">
                    <rgg-confirm :confirm="delete_content" type="delete"
                                 message="content_management.dialog.delete_confirm_message"/>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import {createContent, deleteContent, updateContent, uploadFile} from "@/api/content";

export default {
    name: "ContentManagementDialog",
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },
    data: () => ({
        content: {
            title: '',
            text: '',
            category: {},
            file: '',
            sort: 0,
            type: {},
            videoId: '',
            updateDate: ''
        },
        document_ready: true,

        select_category: [],
        select_type: [],
        type_disabled: false,
        img_url: null,
        file_name: {}
    }),
    created() {
        this.select_category = [
            {alias: this.g_i18_filter('homeseller'), value: 'HomeSeller', default: 'Image', change: true},
            {alias: this.g_i18_filter('homecustomer'), value: 'HomeCustomer', default: 'Image', change: true},
            {alias: this.g_i18_filter('homeadministrator'), value: 'HomeAdministrator', default: 'Image', change: true},
            {alias: this.g_i18_filter('homesupporter'), value: 'HomeSupporter', default: 'Image', change: true},
            {alias: this.g_i18_filter('documents'), value: 'Documents', default: 'File', change: false},
            {alias: this.g_i18_filter('certificates'), value: 'Certificates', default: 'File', change: false},
            {alias: this.g_i18_filter('videos'), value: 'Videos', default: 'Video', change: false},
            {alias: this.g_i18_filter('contest'), value: 'Contest', default: 'Image', change: false},
            {alias: this.g_i18_filter('career'), value: 'Career', default: 'Image', change: false}
        ];
        this.select_type = [
            {index: 0, alias: this.$t('content_management.type.File'), value: 'File'},
            {index: 1, alias: this.$t('content_management.type.Video'), value: 'Video'},
            {index: 2, alias: this.$t('content_management.type.Image'), value: 'Image'},
            {index: 3, alias: this.$t('content_management.type.Article'), value: 'Article'},
        ];

        if (this.edit) {
            let title = this.$t('content_management.dialog.edit_content') + ' ID:' + this.id;
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});

            Object.assign(this.content, this.card_item.raw_data);
            if (this.card_item.type === 'Video') {
                this.content.videoId = this.content.file;
            } else if (this.card_item.type === 'File') {
                this.file_name.name = this.content.file;
            }

        } else {
            this.content.title = '';
            this.content.text = '';
            this.content.category = this.select_category[0];
            //this.content.file = '';
            this.content.sort = 0;
            this.content.type = this.select_category[0].default;
            this.content.videoId = '';
            this.content.updateDate = this.g_getDate();

        }
    },
    computed: {
        getContent() {
            return this.content;
        },
        getImage() {
            return process.env.VUE_APP_RGG_PORTAL_UX_API_URL + "/content/" + this.content.category + "/" + this.content.id + "/data?jwt=" + this.$store.getters.getJWT;
        },
        getFile() {
            return this.file_name;
        }
    },
    methods: {
        filePreview(event) {
            this.file_name = event.target.files[0];
        },
        create_content() {
            this.$root.$emit('overlay', true);
            let content = this.content;
            //content.category = content.category.value;

            switch (content.type) {
                case 'Image':
                    break;

                case 'Video':
                    content.videId = this.content.videoId;
                    break;

                case 'File':
                    break;
            }

            //content.file = this.content.file;
            content.category = content.category.value;

            createContent(content).then((response) => {

                if (response.status === 200) {
                    if (content.type === 'Image' || content.type === 'File') {
                        this.upload_file(response.data);
                    } else {
                        this.$parent.$emit('action_dialog', {func: 'close', data: 'content_created'});
                    }
                }
            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'server_error',
                    msg: 'notification.server_error'
                });
            })
        },
        update_content() {
            let content = this.getContent;
            if (typeof content.category === 'object') {
                content.category = content.category.value;
            }

            updateContent(this.id, content).then(() => {
                if (content.type === 'Image' || content.type === 'File') {
                    this.upload_file(this.id);
                } else {
                    this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                }
            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        },
        delete_content() {
            this.$root.$emit('overlay', true);
            deleteContent(this.id).then(() => {
                this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_deleted'});
            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        },

        upload_file(_id) {
            let formData = new FormData();
            formData.append("file", this.$refs.inputUpload.files[0])

            uploadFile(_id, formData).then(() => {
                if (this.edit) {
                    this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_deleted'});
                } else {
                    this.$parent.$emit('action_dialog', {func: 'close', data: 'content_created'});
                }

            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        },

        reString() {
            let str = this.content.file;
            let rgx = /\/v\/(.{11})|\/embed\/(.{11})/g;

            let code = str.matchAll(rgx);

            return code
        },

        section(_item) {
            if (!_item.change) {
                this.type_disabled = true;
            } else {
                this.type_disabled = false;
            }
            this.content.type = _item.default;
        },

        imagePreview(_e) {
            this.content.file = '';
            const file = _e.target.files[0];
            this.img_url = URL.createObjectURL(file);
        }

    },
    destroyed() {
    }
}
</script>

<style>
.create-content img {
    width: 100%;
    border-radius: 8px;
}
</style>